import {TableBase} from "../../tables/table_base";

class OldestDownloadsVehicle extends TableBase {
  constructor () {
    super('last_card_read_vehicle');
  }

  paintTable($vehicles, last_card_read_vehicle) {
    const vehicleWarning = moment().subtract(60, 'days');
    const vehicleError = moment().subtract(90, 'days');

    if (last_card_read_vehicle.length === 0) {
      $vehicles.find('tbody').html('<tr><td colspan="2">No vehicles to display</td></tr>');
      return;
    }

    for (const vehicle_reg in last_card_read_vehicle) {
      const id = last_card_read_vehicle[vehicle_reg].id,
        last_read = moment(last_card_read_vehicle[vehicle_reg].date);

      const $row = $('<tr>');

      let errorStyle = '';

      if (last_read.isBefore(vehicleWarning)) {
        if (last_read.isBefore(vehicleError)) {
          errorStyle = 'infringement';
        } else {
          errorStyle = 'warning';
        }
      }

      const $infoCell = $('<span>').attr({'data-tbl': 'veh', 'data-id': id}).addClass('hover-preview').text(vehicle_reg);
      const $regCell = $('<td>').append($infoCell);
      const $dataCell = $('<td>').text(last_read.format('ddd Do MMM YYYY')).addClass(errorStyle);

      $row.append($regCell).append($dataCell);
      $vehicles.append($row);
    }
  }
}

$(() => {
  if(app && app.DASHBOARD_CORE) {
    new OldestDownloadsVehicle();
  } else {
    console.warn('DashboardCore not initalized.');
  }
});
